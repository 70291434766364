var accordion = {
    init: function () {
        $( '.js-accordion' ).click( function ( e ) {
            e.preventDefault();
            let $this = $( this );
            if ( $this.next().hasClass( 'c-accordion-active' ) ) {
                $this.next().removeClass( 'c-accordion-active' );
                $this.removeClass( 'c-accordion-active' );
                $this.attr("aria-expanded","false");
                $this.next().slideUp( 350 );
            } else {
                $( '.c-accordion__content' ).removeClass( 'c-accordion-active' );
                $( '.c-accordion__title' ).removeClass( 'c-accordion-active' );
                $( '.c-accordion__content' ).slideUp( 350 );
                $this.next().toggleClass( 'c-accordion-active' );
                $this.toggleClass( 'c-accordion-active' );
                $this.attr("aria-expanded","true");
                $this.next().slideToggle( 350 );
            }
        } );
    }
}