var backtotop = {
    init: function(){

		var $backTop=$('#backTop'),$w=$(window);

		$backTop.click(function(){
			$('body,html').animate({scrollTop:0},800);
			// $backTop.css('background','#364c56');
			return false;
			});
		$w.on('scroll',function(){
			$w.scrollTop()>500?$backTop.removeClass('masked'):
			$backTop.addClass('masked');
		});

    }
}







// WANNEER FILTER UITGEKLPAT OP MOBILE > VERBERG BACKTOP WANT DIE STAAT BOVEN ALLES
/* $('#collapseShow').on('shown.bs.collapse', function () {
	$('.backTop-container').css('right','-80px')
 });
 
 $('#collapseShow').on('hidden.bs.collapse', function () {
	$('.backTop-container').css('right','0')
 }); */