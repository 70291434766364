var fixedheader = {
    init: function(){

        // var screenwidth = $(window).outerWidth()
        var headerheight = $('header').outerHeight();
		var navtopHeight = $('.p-nav__top').outerHeight();
		var totalHeaderheight = -(headerheight - navtopHeight);
		var floatingphoneWidth = $('.floatingphone').outerWidth();
		var floatingphoneHeight = $('.floatingphone').outerHeight();
		var floatingButtonPosition = -((floatingphoneWidth - floatingphoneHeight)/2);

		// if (screenwidth >= 1000) {
            $('main').css('margin-top',headerheight);        
            // $('.floatingphone').css('top',headerheight + 24 - floatingButtonPosition).css('right',floatingButtonPosition);      
            $('.floatingphone').css('top',headerheight + 24 - floatingButtonPosition);      
			$(window).bind('scroll', function () {
				var num = 450; //number of pixels before modifying styles
				if ($(window).scrollTop() > num) {
					$('header').addClass('shrink');
					$('header').css('margin-top',totalHeaderheight);
					$('header .logo img').attr('src','/assets/images/logo-truckador-white.svg');
					$('header.elearning .logo img').attr('src','/assets/images/logo-truckador-green.svg');
				} else {
					$('header').removeClass('shrink');
					$('header').css('margin-top','inherit');
					$('header .logo img').attr('src','/assets/images/logo-truckador-green.svg');
				}
			});
		// } 
		// else { 
			// $('header').removeClass('shrink');
		// }

    }
}