    // ANCHOR SMOOTH ANIMATION
    $(document).on('click', 'a.anchor', function(e){     
        // e.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top-120}, 900);
        // $('.navbar-collapse').css('max-height','0');
        // $('.navbar-toggler').removeClass('active');
        // $(".navbar-collapse").attr("aria-expanded","false");
        // $('.navbar-toggler').toggle('active');
    });

