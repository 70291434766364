var utilities = {
    checkActiveClass: function ( element ) {
        var classNames = element.className.split( ' ' );
        if ( classNames.indexOf( 'active' ) !== -1 ) {
            return true;
        }
    },
    checkIfAncestor: function (element, className){
        if ( !element || element.length === 0 ) {
            return false;
        }
        var parent = element;
        do {
            if ( parent === document ) {
                break;
            }
            if ( parent.className.indexOf( className ) >= 0 ) {
                return true;
            }
        } while ( parent = parent.parentNode );
        return false;
    }
}