$(document).ready(function(){
    // accordion.init();
    // stacktable.init();
})
// window.onload = function () {
$(window).on("load", function () {
    backtotop.init();
    collapse.init();
    dropdown.init();
    fixedheader.init();
    search.init();   
});

$( window ).resize(function() {
    fixedheader.init();
  });