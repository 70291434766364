var search = {
    init: function(){

		var rowheaderHeight = $('.row_header').height(); // MVL
		// var headerHeight = $('.cntr_header').height(); // MVL
		$('.search-inline').css('height',rowheaderHeight -30); // MVL
	
		// console.log(rowheaderHeight); // 180
	//		console.log(headerHeight); // 132
	
		var mobTopNav = $('.navigations .row_navtop').outerHeight(); // MVL	
	//		$('.search-inline-mob').css('height',mobTopNav); // MVL
	
		var sp = document.querySelector('.search-open');
		var searchbar = document.querySelector('.search-inline');
		var shclose = document.querySelector('.search-close');
			// var spMob = document.querySelector('.search-open-mob');
			// var searchbarMob = document.querySelector('.search-inline-mob');
			// var shcloseMob = document.querySelector('.search-close-mob');
	
		function changeClass() {
			searchbar.classList.add('search-visible');
		}
		function closesearch() {
			searchbar.classList.remove('search-visible');
		}
/* 			function changeClassMob() {
				searchbarMob.classList.add('search-visible-mob');
				$('.search-inline-mob.search-visible-mob').css('top',0);
			}
			function closesearchMob() {
				searchbarMob.classList.remove('search-visible-mob');
				$('.search-inline-mob').css('top',-300);
			} */
	
		sp.addEventListener('click', changeClass);
		shclose.addEventListener('click', closesearch);
			// spMob.addEventListener('click', changeClassMob);
			// shcloseMob.addEventListener('click', closesearchMob);
	
	
		// Zet cursor op focus bij uitklappen
		// $('.search-open').click(function(){
		$(".search-open").on("click", function(){
			$('.search-visible input').focus();
		});

    }
}

$(document).on("mouseup", function(e){
    var container = $(".searching .search-inline");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0){
        container.removeClass('search-visible');
    }
});
